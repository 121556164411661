import './App.css';

function App() {
  return (
    <div className="App">
      <video autoPlay muted loop id='video'>
        <source src='https://decentraland.org/static/scenes4-576941eeec2aa15935ebcf45cc3c739c.webm'/>
      </video>
      <div id='navbar'>
        <div id='nav_item'>
          <div id='logo'>
            <img src='https://cryptologos.cc/logos/decentraland-mana-logo.png' alt='Video' />
          </div>
          <div>MARKETPLACE</div>
          <div>BUILDER</div>
          <div>DOCS</div>
          <div>EVENTS</div>
          <div>DAO</div>
          <div>BLOG</div>
        </div>
        <div id='right_nav'>
          <div>JOIN OUR CORD</div>
          <button className='btn2'>START EXPLORING</button>
        </div>
      </div>
      <div id='content'>
        <h1>Welcome to Decentraland</h1>
        <p>Create, explore and trade in the first-ever virtual<br /> world owned by its users.</p>
        <button className='btn'>DOWNLOAD</button>
        <button className='btn1'>OPEN IN BROWSER</button>
      </div>
    </div>
  );
}

export default App;
